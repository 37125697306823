.Message {
  font-size: 1rem;
}

.Disclosure {
  font-size: 12px;
}

.Footer {
  padding: 0px 24px 24px 24px;
}